<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                placeholder="Khoa/bộ môn"
              >
                <v-select
                  v-model="filter.departmentId"
                  :options="departments"
                  :reduce="option => option.value"
                  placeholder="Khoa/bộ môn"
                  @input="onChangeDepartments()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="5">
              <b-form-group
                placeholder="Tổ bộ môn"
              >
                <v-select
                  label="name"
                  v-model="filter.teamId"
                  :options="teacherTeams"
                  :reduce="option => option.id"
                  placeholder="Tổ bộ môn"
                  @input="onSucceed"
                />
              </b-form-group>
            </b-col>
            <b-col cols="2" md="1" class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="onSucceed()"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="FilterIcon" />
                </span>
              </b-button>
            </b-col>
            <b-col cols="10" md="12">
              <div class="text-center">
                <b-button
                  v-if="teacherSubjectUpdatable"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.teacherSubjectModal
                  class="mx-1"
                  variant="info"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="UserCheckIcon" /> Phân công học phần
                  </span>
                </b-button>
                <b-button
                  v-if="creatable"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.teacherSaveModal
                  class="mx-1 mt-1 mt-md-0"
                  variant="success"
                  @click="onCreateTeacher"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="PlusIcon" /> Thêm giảng viên
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-1">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                  đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                mode="remote"
                :columns="columns"
                :rows="teachersDisplay"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                :line-numbers="true"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>

                  <span v-else-if="props.column.field === 'teamId'">
                    {{ getTeamName(props.row.teamId) }}
                  </span>

                  <span v-else-if="props.column.field === 'departmentId'">
                    {{ getDepartmentName(props.row.departmentId) }}
                  </span>

                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      v-if="updatable"
                      v-b-modal.teacherSaveModal
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="onEditTeacher(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDeleteTeacher(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <TeacherSave
      ref="saveModalRef"
      :item="currentTeacher"
      @succeed="onSucceed"
    />
    <TeacherSubject
      ref="teacherSubjectModalRef"
      @succeed="onSucceed"
    />
  </b-container>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import TeacherSave from '@/views/admin/teacherManagement/teachers/TeacherSave.vue'
import TeacherSubject from '@/views/admin/teacherManagement/teachers/TeacherSubject.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  name: 'Teachers',
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    BFormGroup,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    TeacherSave,
    TeacherSubject,
  },
  data() {
    return {
      teachersDisplay: [],
      teacherTeamOptions: [],
      departmentOptions: [],
      filterTeacherTeam: {
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: getUser().orgId,
        departmentId: null,
        leaderId: null,
        status: 1,
      },
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        departmentId: null,
        teamId: null,
        name: null,
        status: null,
        sort: null,
      },
      currentTeacher: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên giảng viên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tổ bộ môn',
          field: 'teamId',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tất cả',
            filterDropdownItems: [],
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Khoa/bộ môn',
          field: 'departmentId',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tất cả',
            filterDropdownItems: [],
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tất cả',
            filterDropdownItems: [],
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      teachers: 'teacher/teachers',
      totalRows: 'teacher/totalRows',
      statuses: 'teacher/statuses',
      departments: 'dropdown/departments',
      teacherTeams: 'teacherTeam/teacherTeams',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.TEACHERS)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.TEACHERS)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.TEACHERS)
    },
    teacherSubjectUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.TEACHER_SUBJECT)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getDepartments({ organizationId: this.filter.organizationId })
      if (this.departments.length > 0) {
        this.filterTeacherTeam.departmentId = this.departments[0].value
        await this.readTeacherTeams(this.filterTeacherTeam)
        if (this.teacherTeams.length > 0) {
          this.filter.teamId = this.teacherTeams[0].value
        }
      }

      await this.readTeachers(this.filter)

      this.teacherTeamOptions = [...this.teacherTeams]
      this.departmentOptions = [...this.departments]
      this.teachersDisplay = [...this.teachers]
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      const teamCV = this.teacherTeamOptions.map(item => ({ value: item.id, text: item.name }))
      this.$set(this.columns[1].filterOptions, 'filterDropdownItems', teamCV)

      const departmentsCV = this.departmentOptions.map(item => ({ value: item.value, text: item.label }))
      this.$set(this.columns[2].filterOptions, 'filterDropdownItems', departmentsCV)

      const statusesCV = this.statuses.map(item => ({ value: item.id, text: item.name }))
      this.$set(this.columns[3].filterOptions, 'filterDropdownItems', statusesCV)

      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      // begin: 'teacher/begin',
      readTeachers: 'teacher/readTeachers',
      deleteTeachers: 'teacher/deleteTeachers',
      readTeacherTeams: 'teacherTeam/readTeacherTeams',
      getDepartments: 'dropdown/getDepartments',
    }),
    async onChangeDepartments() {
      await this.readTeacherTeams({
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: getUser().orgId,
        departmentId: this.filter.departmentId,
      })
    },
    onCreateTeacher() {
      this.currentTeacher = undefined
    },
    onEditTeacher(teacher) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = teacher
      this.currentTeacher = rest
    },
    onDeleteTeacher(teacher) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa giảng viên <span class="text-danger">${teacher.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteTeachers(teacher.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.readTeachers(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getTeachersFromStore()
    },
    async getTeachersFromStore() {
      this.isLoading = true
      try {
        await this.readTeachers(this.filter)
        this.teachersDisplay = this.teachers
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getTeachersFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else this.updateParams({ sort: `2_${type}` })
      }
      await this.getTeachersFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ teacherName: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'departmentId')) {
        this.updateParams({ departmentId: columnFilters.departmentId })
      }
      if (Object.hasOwn(columnFilters, 'teamId')) {
        this.updateParams({ teamId: columnFilters.teamId })
      }
      await this.getTeachersFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    getTeamName(id) {
      if (this.teacherTeamOptions.find(item => item.id === id) != null) {
        return this.teacherTeamOptions.find(item => item.id === id).name
      }
      return ''
    },
    getDepartmentName(id) {
      if (this.departmentOptions.find(item => item.value === id) != null) {
        return this.departmentOptions.find(item => item.value === id).label
      }
      return ''
    },
    async onSucceed() {
      await this.getTeachersFromStore()
    },
  },
}
</script>
